@import "../../styles/colors";
@import "../../styles/spacing";
@import "../../styles/typography";
@import "../../styles/animation";

.projects-page-outermost-div {
  background-color: $primary-background-2;
  min-height: calc(100vh - #{$nav-bar-height});
  max-width: 100vw;
  padding: $margin-1;
  padding-top: 0;
  display: flex;
  flex-direction: column;
}

.projects-page-heading {
  @include heading-0;
  align-self: flex-end;
  width: min-content;
  color: $primary;
  transition: text-shadow linear $animation-duration-long,
    -webkit-text-stroke linear $animation-duration;
  cursor: default;
  text-shadow: $black 0px 0px 6px;
}

.projects-page-heading:hover {
  text-shadow: none;
  -webkit-text-stroke: 1px $black;
}

.projects-page-heading::selection {
  -webkit-text-stroke: 0px $black;
}

.projects-page-projects-list {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: $margin-1;
  row-gap: $margin-1;
}

@media only screen and (max-width: 700px) {
  .projects-page-heading {
    @include heading-3;
    text-shadow: none;
    -webkit-text-stroke: 1px $black;
    margin-top: $nav-bar-height;
  }
}
