@import "../../../../../styles/colors";
@import "../../../../../styles/typography";
@import "../../../../../styles/fonts";
@import "../../../../../styles/spacing";

.contact-form-div {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    visibility: hidden;
  }
}

.contact-form {
  display: flex;
  flex-direction: column;
  padding: 4px;
  width: 90%;
  height: fit-content;
}

.contact-form-field {
  @include text-0;
  background-color: $white;
  border: none;
  outline: none;
  padding-left: 16px;
  font-family: $primary-font;
  border-radius: $border-radius-0;
}

.contact-form-field::placeholder {
  color: $secondary-text;
}

#contact-form-submit-btn {
  background-color: $primary-action;
  border: none;
  outline: none;
  color: $white;
  height: 51px;
  cursor: pointer;
  transition: background-color 124ms ease;
  font-size: x-large;
  width: 100%;
  border-radius: $border-radius-0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#contact-form-submit-btn:hover {
  background-color: $primary-action10;
}

#contact-form-submit-btn:disabled {
  cursor: not-allowed;
}

#email-form-field {
  height: 51px;
  margin-bottom: 24px;
}

#message-form-field {
  margin-bottom: 30px;
  padding-top: 16px;
  resize: none;
}

.form-field-error-text {
  color: $yellow;
}

@media only screen and (max-width: 700px) {
  .contact-form-field {
    width: 90%;
    border-radius: 11px;
    font-size: medium;
  }

  #contact-form-submit-btn {
    border-radius: 11px;
  }
}
