@import "./styles/fonts";
@import "./styles/colors";

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt/Prompt-ExtraLight.ttf);
  font-weight: lighter;
}

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt/Prompt-Thin.ttf);
  font-weight: 100;
}

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt/Prompt-Light.ttf);
  font-weight: 200;
}

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt/Prompt-Medium.ttf);
  font-weight: 300;
}

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt/Prompt-SemiBold.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt/Prompt-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt/Prompt-ExtraBold.ttf);
  font-weight: bolder;
}

@font-face {
  font-family: "Prompt";
  src: url(./assets/fonts/Prompt/Prompt-Regular.ttf);
  font-weight: normal;
}

body {
  margin: 0;
  padding: 0;
  color: $white;
  font-family: $primary-font;
}

h1::selection {
  background-color: $primary;
  color: $white;
}
h2::selection {
  background-color: $primary;
  color: $white;
}
h3::selection {
  background-color: $primary;
  color: $white;
}
h4::selection {
  background-color: $primary;
  color: $white;
}
h5::selection {
  background-color: $primary;
  color: $white;
}
h6::selection {
  background-color: $primary;
  color: $white;
}
p::selection {
  background-color: $primary;
  color: $white;
}
span::selection {
  background-color: $primary;
  color: $white;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}
