@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/spacing";

footer {
  height: $nav-bar-height;
  width: 100%;
  margin: 0;
  background-color: $primary-background-1;
}

.footer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}

.social-links-div {
  @include heading-4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-link {
  text-decoration: none;
  color: $white;
}

.footer-heart-icon {
  color: $primary;
}

.social-link-icon {
  margin: 0 4px;
  cursor: pointer;
}

.social-link-icon:hover {
  color: $primary;
}

@media only screen and (max-width: 700px) {
  .footer {
    flex-direction: column;
    justify-content: center;
    p {
      @include text-5;
      margin: 0px;
      margin-bottom: 4px;
    }
  }

  .social-links-div {
    font-size: unset;
  }
  .social-link-icon {
    @include text-4;
  }
}
