@import "../../../../styles/colors";
@import "../../../../styles/typography";
@import "../../../../styles/spacing";

.about-me-section {
  min-height: 100vh;
  width: 100%;
  background-color: $primary-background-1;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
}

.about-me-text {
  @include heading-4;
  width: 50%;
  color: $primary10;
  margin-left: 7%;
}

.about-me-img {
  position: absolute;
  height: auto;
  width: 42%;
  bottom: 0%;
  right: 0%;
}

@media only screen and (max-width: 600px) {
  .about-me-section {
    min-height: fit-content;
    padding: $margin-0 0;
  }

  .about-me-text {
    @include text-3;
    width: auto;
    margin-right: 7%;
    text-align: justify;
  }

  .about-me-img {
    display: none;
  }
}
