.work-cycle-section {
  background-color: #eeeeee;
  height: 100vh;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 80%;
    height: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .work-cycle-section {
    height: 70vh;

    img {
      width: 90%;
      height: 90%;
    }
  }
}
