@import "../../../../styles/colors";
@import "../../../../styles/spacing";

.book-of-the-month-outer-div {
    width: 100%;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    h1 {
        text-decoration: underline;
    }
}

.book-details-outer-div {
    display: flex;
    width: fit-content;
    max-width: 54vw;
    min-width: 50vw;
    color: $black;
    background-color: $yellow;
    padding: $margin-4;
    justify-content: space-between;
}

.book-details-div{
    margin: $margin-0;
}

.rating-bar-div {
    color: $primary;
}