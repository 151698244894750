@import "../../../../styles/colors";
@import "../../../../styles/spacing";
@import "../../../../styles/animation";
@import "../../../../styles/typography";

.project-card {
  width: 100%;
  aspect-ratio: 16 / 9;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: $border-radius-0;
  position: relative;
  overflow: hidden;
}

.project-card-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba($color: $black, $alpha: 0.6);
  opacity: 0;
  transition: all ease-in-out $animation-duration-long;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  * {
    opacity: 0;
    transition: opacity ease-in-out $animation-duration-long;
  }
  a {
    text-decoration: none;
  }
}

.project-card:hover {
  .project-card-overlay {
    opacity: 1;

    * {
      opacity: 1;
    }
  }
}

.project-card-info {
  color: $white;
  text-align: center;
}

.project-card-info:hover {
  .project-card-title {
    color: $primary;
  }
}

.project-card-title {
  @include text-0;
  margin: auto $margin-1;
  color: $primary10;
}

.project-card-description {
  @include text-1;
  margin: auto $margin-1;
}

@media only screen and (max-width: 600px) {
  .project-card-title {
    @include text-3;
    margin: auto $margin-5;
  }

  .project-card-description {
    display: none;
  }
}
