.left-hero-section {
  height: 100%;
  flex: 1;
  background-image: url(../../../../../assets/boy3d.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 700px) {
  .left-hero-section {
    display: none;
  }
}
