@mixin heading-0 {
  font-size: 12rem;
}

@mixin heading-1 {
  font-size: 10rem;
}

@mixin heading-2 {
  font-size: 8rem;
}

@mixin heading-3 {
  font-size: 4rem;
}

@mixin heading-4 {
  font-size: 2rem;
}

@mixin text-0 {
  font-size: 24px;
}

@mixin text-1 {
  font-size: 20px;
}

@mixin text-2 {
  font-size: 18px;
}

@mixin text-3 {
  font-size: 16px;
}

@mixin text-4 {
  font-size: 14px;
}

@mixin text-5 {
  font-size: 12px;
}
