@import "../../../../styles/spacing";
@import "../../../../styles/typography";
@import "../../../../styles/colors";

.blog-card {
  width: min-content;
  color: $black;
  a {
    text-decoration: none;
    .cta-read-more {
      width: min-content;
    }
  }
  display: flex;
  flex-direction: column;
}

.title-link {
  color: $black;
}

.blog-card-title {
  @include heading-4;
  text-decoration: underline 2.24px;
  font-weight: 200;
}

.blog-card-published-date {
  @include text-3;
  color: $grey;
  margin: $margin-4 0;
}

.blog-card-thumbnail {
  width: calc(100vw / 2);
  aspect-ratio: 16 / 9;
  object-fit: contain;
  border-radius: $border-radius-0;
  align-self: center;
  box-shadow: 0 0 4px $black;
}

.blog-card-description {
  @include text-3;
  margin: $margin-4 0;
  font-weight: 200;
}

.blog-card-read-more-btn {
  cursor: pointer;
  background-color: $primary;
  border: none;
  width: max-content;
  border-radius: $border-radius-1;
  padding: $margin-4 $margin-1;
  color: $white;
}

.blog-card-read-more-btn:hover {
  background-color: $primary20;
}

@media only screen and (max-width: 600px) {
  .blog-card {
    width: auto;
  }
  .blog-card-title {
    @include text-1;
  }
  .blog-card-thumbnail {
    margin-top: $margin-5;
    width: 100%;
  }
  .blog-card-description {
    @include text-4;
    font-weight: 200;
  }
  .blog-card-read-more-btn {
    padding: $margin-5 $margin-3;
  }
}
