@import "../../../../../styles/colors";
@import "../../../../../styles/typography";

.about-me-heading-div {
  @include heading-3;
  padding-top: 4%;
  display: flex;
  margin-left: 7%;
}

.about-me-heading-div h1 {
  text-shadow: 11px 0 0 rgba($color: $grey, $alpha: 0.5);
}

.letter-B {
  transform: rotateZ(180deg);
  margin-right: 8px;
  position: relative;
  bottom: 4px;
  text-shadow: -11px 0 0 rgba($color: $grey, $alpha: 0.5) !important;
}

@media only screen and (max-width: 700px) {
  .about-me-heading-div {
    @include text-1;
  }

  .about-me-heading-div h1 {
    text-shadow: 4px 0 0 rgba($color: $grey, $alpha: 0.5);
  }

  .letter-B {
    text-shadow: -4px 0 0 rgba($color: $grey, $alpha: 0.5) !important;
    bottom: 2px;
    margin-right: 3px;
    margin-left: 3px;
    margin-top: 1px;
  }
}
