@import "../../../../styles/colors";
@import "../../../../styles/typography";
@import "../../../../styles/spacing";

.contact-me-section {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$nav-bar-height});
  width: 100%;
  background: radial-gradient(
    circle,
    rgba(245, 248, 250, 1) 0%,
    rgba(133, 100, 181, 1) 0%,
    rgba(177, 157, 208, 1) 0%,
    rgba(140, 108, 185, 1) 0%,
    rgba(82, 32, 149, 1) 100%
  );
  align-items: center;
  position: relative;
  box-sizing: border-box;
}

.contact-me-section-header-text-1 {
  @include heading-3;
  color: $white;
  margin-top: $nav-bar-height
}

.contact-me-section-header-text-2 {
  @include heading-4;
  font-weight: 300;
  color: $black;
}

.contact-me-section-form-and-social-links-row {
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media only screen and (max-width: 700px) {
  .contact-me-section {
    padding: 72px 24px;
    height: auto;
    width: auto;
  }

  .contact-me-section-header-text-1 {
    @include heading-4;
  }

  .contact-me-section-header-text-2 {
    @include text-0;
    margin-bottom: $margin-2;
  }
}
