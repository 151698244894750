// Background Colors
$primary-background-1: #141e55;
$primary-background-2: #eeeeee;

// Primary Colors
$primary: rgb(216, 112, 147);
$primary10: rgb(224, 152, 176);
$primary20: rgb(163, 53, 90);
$primary-action: #3275da;
$primary-action10: #124ca3;

// Text
$secondary-text: #d4d4d4;

// Standard Colors
$white: white;
$black: black;
$grey: grey;
$yellow: #ffd500;
