@import "../../../../styles/colors";
@import "../../../../styles/typography";
@import "../../../../styles/animation";

.featured-section {
  height: 100vh;
  width: 100%;
  background-color: $primary-background-2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.featured-banner {
  height: 50%;
  width: 50%;
  background-color: #eeeeee;
  margin: 2%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  color: $white;
}

.featured-banner img {
  position: absolute;
  height: 100%;
  width: 100%;
}

.featured-banner-overlay {
  @include heading-3;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba($color: $black, $alpha: 0.4);
  opacity: 0.7;
  transition: all linear $animation-duration;
  display: flex;
  justify-content: center;
  align-items: center;

  h3:hover {
    color: $primary;
  }
}

.featured-banner-overlay:hover {
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .featured-section {
    height: 70vh;
    width: auto;
    flex-direction: column;
  }

  .featured-banner {
    width: 80%;
    height: 40%;
  }

  .featured-banner-overlay {
    @include heading-4;
    opacity: 1;
  }
}
