@import "../../styles/colors";
@import "../../styles/spacing";
@import "../../styles/typography";
@import "../../styles/animation";

.blog-page-outermost-div {
  background-color: $primary-background-2;
  min-height: 100vh;
  max-width: 100vw;
  padding: $margin-1;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-page-heading {
  @include heading-0;
  align-self: flex-end;
  width: min-content;
  color: $primary;
  transition: text-shadow linear $animation-duration-long,
    -webkit-text-stroke linear $animation-duration;
  cursor: default;
  text-shadow: $black 0px 0px 6px;
}

.blog-page-heading:hover {
  text-shadow: none;
  -webkit-text-stroke: 1px $black;
}

.blog-page-heading::selection {
  -webkit-text-stroke: 0px $black;
}

.blog-page-blogs-list {
  display: grid;
  row-gap: $margin-0;
}

@media only screen and (max-width: 700px) {
  .blog-page-heading {
    @include heading-3;
    text-shadow: none;
    -webkit-text-stroke: 1px $black;
    margin-top: $nav-bar-height;
  }
}
