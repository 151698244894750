@import "../../../../styles/spacing";

.hero-section {
  height: calc(100vh - #{$nav-bar-height});
  margin-top: $nav-bar-height;
  width: 100%;
  display: flex;
}

@media only screen and (max-width: 600px) {
  .hero-section {
    height: 70vh;
  }
}
