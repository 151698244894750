$nav-bar-height: 54px;
$border-radius-0: 8px;
$border-radius-1: 4px;
$margin-0: 32px;
$margin-1: 24px;
$margin-2: 20px;
$margin-3: 16px;
$margin-4: 12px;
$margin-5: 8px;
$margin-6: 4px;
