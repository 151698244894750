@import "../../../styles/colors";

.page-not-found-div {
  background-image: url(https://cdn.dribbble.com/users/111026/screenshots/18549362/media/522935cf7a246534cfcc6d36a05b3532.png?resize=1600x1200&vertical=center);
  height: 100vh;
  width: 100vw;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
