@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/spacing";

.book-tag {
  @include text-5;
  border: $primary20 solid 1px;
  background-color: $primary20;
  width: fit-content;
  border-radius: 24px;
  padding: 0px 8px;
  color: $white;
  margin: 2px $margin-6;
}
