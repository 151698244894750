.social-graph-div {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
}

.social-graph-img {
  object-fit: scale-down;
}

@media only screen and (max-width: 600px) {
  .social-graph-div {
    display: none;
  }
}
