@import "../../../styles/colors";

.loader {
  width: 24px;
  height: 24px;
  border: 6px solid $primary-background-1;
  border-right: 6px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
