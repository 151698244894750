@import "../../../../../styles/typography";
@import "../../../../../styles/colors";

.right-hero-section {
  height: 100%;
  flex: 1;
  background-color: $primary-background-1;
  display: flex;
  flex-direction: column;
  padding-left: calc((100vw / 2) * (0.1));
}

.right-hero-section h1 {
  @include heading-0;
  margin-top: 24%;
  color: $primary;
}

.right-hero-section h2 {
  @include heading-3;
}

.right-hero-section h3 {
  @include heading-4;
  color: $primary10;
  font-weight: 400;
}

#info-titles {
  @include heading-4;
  color: $primary10;
}

.social-links-row-hero-section {
  @include heading-4;
  display: flex;
  align-items: unset;
  justify-content: unset;
}

.social-links-row-hero-section .social-link-icon {
  @include heading-4;
}

@media only screen and (max-width: 700px) {
  .right-hero-section {
    justify-items: center
  }
  .right-hero-section h1 {
    @include heading-1;
    line-height: 192px;
  }

  .right-hero-section h2 {
    @include heading-4;
  }

  .right-hero-section h3 {
    @include text-1;
  }
}