.amazon-button {
    display: flex;
    align-items: center;
    background-color: #ff6f00;
    color: #000;
    text-decoration: none;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    width: fit-content;
  }
  
  .amazon-button:hover {
    background-color: #FF9900;
  }
  
  .amazon-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  