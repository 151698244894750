@import "../../../../styles/colors";
@import "../../../../styles/spacing";
@import "../../../../styles/animation";

.book-card {
    display: flex;
    flex-direction: column;
    margin: $margin-0 $margin-4;
    width: 224px;
    transition: box-shadow linear $animation-duration;
    box-shadow: $black 2px 2px;
}

.book-card-title-and-author {
    color: $black;
}

.book-card-thumbnail {
    height: 324px;
    width: 224px;
}

.book-card:hover {
    box-shadow: $grey 4px 4px 11px;
}