@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/animation";
@import "../../../styles/spacing";

header {
  height: $nav-bar-height;
  width: 100vw;
  margin: 0;
  // background: linear-gradient(to right, white 47.49%, $primary-background-1 47.49%);
  background-color: $primary-background-1;
  box-shadow: 1px 1px 11px $primary-background-1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.header {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;

  a {
    text-decoration: none;
    color: $white;
  }
}

.nav-links-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;

  .nav-link {
    @include text-2;
    text-decoration: none;
    color: $white;
    margin: 0px 6px;
    transition: color linear $animation-duration;
  }
}

.nav-link-selected {
  color: $primary !important;
}

.nav-link:hover {
  color: $primary;
}

@media only screen and (max-width: 700px) {
  .nav-links-row{
    .nav-link {
      @include text-4;
    }
  }
  .nav-link {
    @include text-4;
  }
}
