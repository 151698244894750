@import "../../styles/colors";
@import "../../styles/spacing";
@import "../../styles/typography";
@import "../../styles/animation";

.myreads-page-outermost-div {
  background-color: $primary-background-2;
  min-height: 100vh;
  max-width: 100vw;
  padding: 2 * $margin-0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $primary10;
}

.reading-list-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    
    h2 {
        @include heading-4;
        text-decoration: underline
    }
}

.myreads-page-heading {
  @include heading-0;
  align-self: flex-end;
  color: $primary;
  transition: text-shadow linear $animation-duration-long,
    -webkit-text-stroke linear $animation-duration;
  cursor: default;
  text-shadow: $black 0px 0px 6px;
}

.myreads-page-books-list {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
}

.myreads-page-heading:hover {
  text-shadow: none;
  -webkit-text-stroke: 1px $black;
}

.myreads-page-heading::selection {
  -webkit-text-stroke: 0px $black;
}

@media only screen and (max-width: 600px) {
  .myreads-page-heading {
    @include heading-3;
    text-shadow: none;
    -webkit-text-stroke: 1px $black;
  }
}
